import React, { useEffect, useState } from 'react';
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  getPaginationRowModel,
  flexRender,
} from '@tanstack/react-table';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { parseISO } from 'date-fns';
import { uk } from 'date-fns/locale';

import { ReactComponent as CustomerIcon } from './../assets/images/svg/client.svg';
import { ReactComponent as FilterIcon } from './../assets/images/svg/filter.svg';

const Table = ({ rest }) => {
  const { columns, data, onDoubleClick = () => {}, search = true, pagination = true, filters = false } = rest;

  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [filteredData, setFilteredData] = useState(data);
  const [paginationState, setPaginationState] = useState({
    pageIndex: 0,
    pageSize: 50, 
  });

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  const table = useReactTable({
    data: filteredData,
    columns,
    state: {
      columnVisibility: {
        products: false,
      },
      pagination: paginationState,
      columnFilters,
      globalFilter,
    },
    onColumnFiltersChange: setColumnFilters,
    onPaginationChange: setPaginationState,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const handleFilterChange = (value) => {
    setGlobalFilter(value);
  };

  const renderSearch = search ? (
    <div className="col">
      <GlobalFilter filter={globalFilter} setFilter={handleFilterChange} />
    </div>
  ) : null;

  return (
    <>
      <div className="row align-items-center">
        {filters && filters.length !== 0 && (
          <div
            data-bs-toggle="collapse"
            data-bs-target="#navbarToggleExternalContent"
            className="col-auto border border-2 border-black rounded p-2 filters"
          >
            <FilterIcon />
          </div>
        )}
        {renderSearch}
        {filters && filters.length !== 0 && (
          <div className="collapse mt-3" id="navbarToggleExternalContent">
            <h5 className="text-body-emphasis h5">Фільтрувати по:</h5>
            <AdditionalFilters filters={filters} data={data} setFilteredData={setFilteredData} />
          </div>
        )}
      </div>
      <div className="row">
        <table className="table table-hover table-bordered mt-2">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <>
                        <div
                          {...{
                            className: header.column.getCanSort() ? 'cursor-pointer select-none' : '',
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          {flexRender(header.column.columnDef.header, header.getContext())}
                          {{
                            asc: ' 🔼',
                            desc: ' 🔽',
                          }[header.column.getIsSorted()] ?? null}
                        </div>
                        {header.column.getCanFilter() && (
                          <div>
                            <Filter column={header.column} />
                          </div>
                        )}
                      </>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id} onDoubleClick={() => onDoubleClick(row.original.id)}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    {cell.column.id.includes('paymentColumn') && row.original?.is_customer === '1' && (
                      <div className="position-absolute top-0 end-0" style={{ width: 35, height: 35, fill: 'green' }}>
                        <CustomerIcon />
                      </div>
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {pagination && (
        <div className="row justify-content-center mt-3">
          <div className="col-auto">
            <div className="d-flex justify-content-center align-items-center">
              <button
                className="btn btn-secondary me-2"
                onClick={() => table.setPageIndex(0)}
                disabled={!table.getCanPreviousPage()}
              >
                {'<<'}
              </button>
              <button
                className="btn btn-secondary me-2"
                onClick={() => table.previousPage()}
                disabled={!table.getCanPreviousPage()}
              >
                {'<'}
              </button>
              <span className="d-flex align-items-center me-2">
                Сторінка {table.getState().pagination.pageIndex + 1} из {table.getPageCount()}
              </span>
              <button
                className="btn btn-secondary me-2"
                onClick={() => table.nextPage()}
                disabled={!table.getCanNextPage()}
              >
                {'>'}
              </button>
              <button
                className="btn btn-secondary"
                onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                disabled={!table.getCanNextPage()}
              >
                {'>>'}
              </button>
              <select
                className="form-select ms-2"
                value={table.getState().pagination.pageSize}
                onChange={(e) => table.setPageSize(Number(e.target.value))}
              >
                {[50, 100, 150, 200].map((size) => (
                  <option key={size} value={size}>
                    Показати {size}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const AdditionalFilters = ({ filters, data, setFilteredData }) => {
  // Состояние для диапазонов `date_doc` и `date_end`
  const [dateDocRange, setDateDocRange] = useState([null, null]);
  const [dateEndRange, setDateEndRange] = useState([null, null]);

  // Функция для фильтрации по диапазону `date_doc`
  const handleDateDocRangeChange = (start, end) => {
    setDateDocRange([start, end]);
    applyFilters(start, end, dateEndRange[0], dateEndRange[1]);
  };

  const handleDateEndRangeChange = (start, end) => {
    setDateEndRange([start, end]);
    applyFilters(dateDocRange[0], dateDocRange[1], start, end);
  };

  const applyFilters = (docStart, docEnd, endStart, endEnd) => {
    const filteredData = data.filter((item) => {
      const dateDoc = parseISO(item.date_doc);
      const dateEnd = parseISO(item.date_end);

      const isDateDocInRange =
        (!docStart || dateDoc >= docStart) && (!docEnd || dateDoc <= docEnd);
      const isDateEndInRange =
        (!endStart || dateEnd >= endStart) && (!endEnd || dateEnd <= endEnd);

      return isDateDocInRange && isDateEndInRange;
    });

    setFilteredData(filteredData);
  };

  // Сброс фильтров
  const resetFilters = () => {
    setDateDocRange([null, null]);
    setDateEndRange([null, null]);
    setFilteredData(data);
  };

  return (
    <>
      <div className="row align-items-center mb-2">
        {filters.includes("dateDocRange") && (
          <div className="col-auto border border-2 me-2 p-2">
            <h6>даті початку:</h6>
            <div className="mb-3 row">
              <label className="col-4">з:</label>
              <div className="ml-auto col-auto">
                <DatePicker
                  selected={dateDocRange[0]}
                  onChange={(date) => handleDateDocRangeChange(date, dateDocRange[1])}
                  selectsStart
                  startDate={dateDocRange[0]}
                  endDate={dateDocRange[1]}
                  dateFormat="dd.MM.yyyy"
                  placeholderText="Дата з"
                  locale={uk}
                />
              </div>
            </div>
            <div className="mb-3 row">
              <label className="col-4">по:</label>
              <div className="ml-auto col-auto">
                <DatePicker
                  selected={dateDocRange[1]}
                  onChange={(date) => handleDateDocRangeChange(dateDocRange[0], date)}
                  selectsEnd
                  startDate={dateDocRange[0]}
                  endDate={dateDocRange[1]}
                  dateFormat="dd.MM.yyyy"
                  placeholderText="Дата по"
                  minDate={dateDocRange[0]}
                  locale={uk}
                />
              </div>
            </div>
          </div>
        )}

        {filters.includes("dateEndRange") && (
          <div className="col-auto border border-2 me-2 p-2">
            <h6>даті закінчення:</h6>
            <div className="mb-3 row">
              <label className="col-4">з:</label>
              <div className="ml-auto col-auto">
                <DatePicker
                  selected={dateEndRange[0]}
                  onChange={(date) => handleDateEndRangeChange(date, dateEndRange[1])}
                  selectsStart
                  startDate={dateEndRange[0]}
                  endDate={dateEndRange[1]}
                  dateFormat="dd.MM.yyyy"
                  placeholderText="Дата з"
                  locale={uk}
                />
              </div>
            </div>
            <div className="mb-3 row">
              <label className="col-4">по:</label>
              <div className="ml-auto col-auto">
                <DatePicker
                  selected={dateEndRange[1]}
                  onChange={(date) => handleDateEndRangeChange(dateEndRange[0], date)}
                  selectsEnd
                  startDate={dateEndRange[0]}
                  endDate={dateEndRange[1]}
                  dateFormat="dd.MM.yyyy"
                  placeholderText="Дата по"
                  minDate={dateEndRange[0]}
                  locale={uk}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="row">
        <button onClick={resetFilters} className="btn btn-primary w-auto">
          Скинути фільтри
        </button>
      </div>
    </>
  );
};

function Filter({ column }) {
  const columnFilterValue = column.getFilterValue();
  const filterVariant = column.columnDef.meta?.filterVariant;

  const handleChange = (value) => {
    column.setFilterValue(value === '' ? undefined : value);
  };

  return filterVariant === 'range' ? (
    <div>
      <div className="flex space-x-2">
        <input
          type="number"
          value={(columnFilterValue || [])[0] || ''}
          onChange={(e) => handleChange([e.target.value, (columnFilterValue || [])[1]])}
          placeholder={`Min`}
          className="w-24 border rounded"
        />
        <input
          type="number"
          value={(columnFilterValue || [])[1] || ''}
          onChange={(e) => handleChange([(columnFilterValue || [])[0], e.target.value])}
          placeholder={`Max`}
          className="w-24 border rounded"
        />
      </div>
    </div>
  ) : filterVariant === 'select' ? (
    <select
      onChange={(e) => column.setFilterValue(e.target.value || undefined)}
      value={columnFilterValue || ''}
    >
      <option value="">All</option>
      <option value="complicated">complicated</option>
      <option value="relationship">relationship</option>
      <option value="single">single</option>
    </select>
  ) : filterVariant === 'text' ? (
    <input
      className="w-100 border"
      onChange={(e) => handleChange(e.target.value)}
      placeholder={`Пошук...`}
      type="text"
      value={columnFilterValue || ''}
    />
  ) : null;
}

function GlobalFilter({ filter, setFilter }) {
  return (
    <div>
      <input
        value={filter || ''}
        onChange={(e) => setFilter(e.target.value)}
        placeholder={`Пошук...`}
        className="p-2 w-100 border"
      />
    </div>
  );
}

export default Table;
