import { useDispatch, useSelector } from "react-redux"
import Modal from "./Modal";
import { addToast } from "../redux/slices/toastSlice";
import { updateLastDate } from "../redux/slices/productsSlice";

const itemsTypes = [
    {type: 'product', title: 'продукту', text: 'Ви дійсно хочете видалити продукт?'},
    {type: 'license', title: 'ліцензії', text: 'Ви дійсно хочете видалити ліцензію?'},
    {type: 'customer', title: 'клієнта', text: 'Ви дійсно хочете видалити клієнта?', },
    {type: 'user', title: 'користувача', text: 'Ви дійсно хочете видалити користувача?'},
    {type: 'directory', title: 'продукт', text: 'Ви дійсно хочете видалити продукт?'},
]


const DeleteModal = ({rest}) => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.users.user) || {};
    const {itemType, action, isModalDeleteOpen, closeDeleteModal, tabAction = null} = rest;

    const handleClick = () => {
        if (tabAction) {
            dispatch(tabAction())
        }
        dispatch(action())
        .then(res => {
            if (res.payload.status === 'ok') {
                dispatch(addToast({id: Date.now(), message: res.payload.message, status: res.payload.status}));

                if (action === 'license' && res.payload.data?.lastLicense && res.payload.data?.lastLicense !== null) {
                    const { details = null, date_end = null, id:lastLicense = null} = res.payload.data?.lastLicense;

                    dispatch(updateLastDate({details, date_end, id:lastLicense}))
                } else {
                    dispatch(updateLastDate({id: res.payload.data?.productId}))
                }
            } else {
                dispatch(addToast({id: Date.now(), message: res.payload.message, status: res.payload.status}));
            }
        });
        closeDeleteModal();
    }

    const content = itemsTypes.map(item => {
        const {type, title, text} = item;
        if (itemType === type) {
            return (
                <div key={type} className="modal-dialog">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="staticBackdropLabel">Видалення {title}</h1>
                        <button type="button" className="btn-close" onClick={closeDeleteModal}></button>
                    </div>
                    <div className="modal-body">
                        <p>
                            {text}
                        </p>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={closeDeleteModal}>Ні</button>
                        <button type="button" className="btn btn-danger" onClick={() => handleClick()}>Так</button>
                    </div>
                    </div>
                </div>
            )
        }

        return null;
    })

    const render = user?.rigths !== '1' ? <WarningMessage rest={{closeDeleteModal}} /> : content;

    return (
        <Modal isOpen={isModalDeleteOpen} onClose={closeDeleteModal}>
            {render}
        </Modal>
    )
    
}

const WarningMessage = ({rest}) => {
    const {closeDeleteModal} = rest;

    return (
        <div className="modal-dialog">
            <div className="modal-content">
            <div className="modal-header">
                <h1 className="modal-title fs-5" id="staticBackdropLabel">Попередження</h1>
                <button type="button" className="btn-close" onClick={closeDeleteModal}></button>
            </div>
            <div className="modal-body">
                <p>
                    Недостатньо прав для видалення!
                </p>
            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={closeDeleteModal}>Зрозуміло</button>
            </div>
            </div>
        </div>
    )
}

export default DeleteModal;