import { Formik,} from "formik";
import * as Yup from 'yup'
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { deleteCustomer, remove, setCurrentCustomer, update, updateCustomer } from "../redux/slices/customersSlice";
import { addProduct, update as updateProductState, updateProduct, deleteProduct, changeProductsCustomer, setCurrentProduct, updateLastDate} from "../redux/slices/productsSlice";
import { addLicense, update as updateLicenseState,  updateLicense, deleteLicense, downloadLicense, changeLicensesCustomer, setCurrentLicense, licenseRenewal as renewal, updateLicenseDate} from "../redux/slices/licensesSlice";
import { addYears, format,} from "date-fns";
import { useEffect, useMemo, useState } from "react";
import { importLicenses } from "../redux/slices/usersSlice";
import { addTab, removeTab } from "../redux/slices/tabsSlice";
import { addToast } from "../redux/slices/toastSlice";

import TextInput from "../components/TextInput";
import Select from "../components/Select";
import Table from "../components/Table";
import Modal from "../components/Modal";
import DateInput from "../components/DateInpyt";
import CustomersSelect from "../components/CustomersSelect";
import DeleteModal from "../components/Delete";

import { ReactComponent as TrashIcon } from '../assets/images/svg/trash.svg';
import { ReactComponent as DownloadIcon }   from '../assets/images/svg/download.svg';
import { ReactComponent as CreateIcon } from '../assets/images/svg/createLic.svg'
import { Checkbox } from "../components/Checkbox";



const CustomerPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { customerId } = useParams();
    const [tab, setTab] = useState('products')
    const customer = useSelector(state => state.customers.customers).find(c => c.id === customerId) ?? [];
    const user = useSelector(state => state.users.user);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        if (customer.length !== 0) {
            dispatch(addTab({id: customer.id, name: customer.name}));
        }
    }, [])

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);
   
    if (customer.length === 0) return navigate('/customers');

    const tabContent = tab === 'products' ? <Products rest={{customerId, user}}/> : <Licenses  rest={{customerId, user}}/>;

    const content = (
        <>
            <div className="row mb-3">
                <CustomerForm rest={{customer, openModal, user}}/>
            </div>
            <div className="row">
                <nav>
                    <div className="nav nav-tabs justify-content-center" id="nav-tab" role="tablist">
                        <button onClick={() => setTab('products')} className="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Продукти</button>
                        <button onClick={() => setTab('licenses')} className="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Ліцензії</button>
                    </div>
                </nav>
                <div className="tab-content" id="myTabContent">
                    {tabContent}
                </div>
            </div>
            <ImportLicenses rest={{customerId, isModalOpen, closeModal}}/>
        </>
    )

    return content
};

const ImportLicenses = ({rest}) => {
    const dispatch = useDispatch();
    const {customerId = null, isModalOpen, closeModal, data = [] } = rest;

    return (
        <Modal isOpen={isModalOpen} onClose={closeModal}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="exampleModalLabel">Імпорт Ліцензій</h1>
                        <button type="button" className="btn-close" onClick={closeModal}></button>
                    </div>
                    <div className="modal-body">
                    <Formik
                        initialValues={{ importedCustomer: '' }}
                        validationSchema={validationSchemaImportLicenses}
                        onSubmit={(values, { setSubmitting, resetForm }) => {
                            setSubmitting(true);

                           
                            dispatch(importLicenses({data: {importedCustomer: values.importedCustomer, customerId}}))
                            .then(data => {
                                if (data.payload.status === 'ok') {
                                    const {current, imported} = data.payload.data;
                                    dispatch(changeProductsCustomer({current, imported}))
                                    dispatch(changeLicensesCustomer({current, imported}))
                                    dispatch(addToast({id: Date.now(), message: data.payload.message, status: data.payload.status}));
                                    dispatch(removeTab(imported))
                                    dispatch(remove(imported))

                                    setSubmitting(false);
                                    closeModal();
                                } else {
                                    dispatch(addToast({id: Date.now(), message: data.payload.message, status: data.payload.status}));
                                }
                            })
                            resetForm();
                            
                        }}
                    >
                        {({ handleSubmit, isSubmitting, setFieldValue, values }) => {
                          

                            return (
                            <form onSubmit={handleSubmit} className="row">
                                <div className="col-12">
                                    <p className="d-inline-flex gap-1">
                                        <button className="btn btn-primary" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                                           Інструкція
                                        </button>
                                    </p>
                                    <div className="collapse" id="collapseExample">
                                        <div className="card card-body">
                                            <ul className="list-group">
                                                <li className="list-group-item">
                                                <strong>Виберіть клієнта:</strong>  
                                                <p>У списку виберіть клієнта, від якого бажаєте імпортувати ліцензії.</p>
                                                </li>
                                                <li className="list-group-item">
                                                <strong>Підтвердіть дію:</strong>  
                                                <p>Після вибору клієнта, підтвердьте імпорт. Ліцензії цього клієнта будуть перенесені до поточного облікового запису.</p>
                                                </li>
                                                <li className="list-group-item">
                                                <strong>Видалення клієнта:</strong>  
                                                <p>Клієнт, від якого ви імпортуєте ліцензії, буде автоматично видалений після завершення процесу.</p>
                                                </li>
                                                <li className="list-group-item">
                                                <strong>Завершення:</strong>  
                                                <p>Після успішного перенесення ліцензій, перевірте їх наявність у вашому обліковому записі.</p>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <CustomersSelect
                                        label="Оберіть клієнта"
                                        id="importedCustomer"
                                        name="importedCustomer"
                                        customerId={customerId}
                                    />
                                </div>
                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        className="btn btn-secondary me-2"
                                        onClick={closeModal}
                                    >
                                        Закрити
                                    </button>
                                    <button
                                        type="submit"
                                        className="btn btn-primary w-atuo me-2"
                                        disabled={isSubmitting}
                                    >
                                        {isSubmitting ? 'Імпорт...' : 'Імпортувати'}
                                    </button>
                                </div>
                            </form>
                            );
                        }}
                        </Formik>
                    </div>     
                </div>
            </div>
        </Modal>
    )
}

const Products = ({rest}) => {
    const dispatch = useDispatch();
    const { customerId, user } = rest;
    const data = useSelector(state => state.products.products).filter(p => p.customer === customerId);
    const id = useSelector(state => state.products.currentProduct);
    const goods = useSelector(state => state.appInfo.goods);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
    const [isModalRenewalOpen, setIsModalRenewalOpen] = useState(false);
    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    const columns = useMemo(
        () => [
            {
                id: 'type',
                header: 'Тип',
                accessorKey: 'type',
                cell: ({ row }) => {
                    const good = goods.find(g => g.id === row.original.type);
                    return good ? good.name : '';
                },
            },
            {
                id: 'details',
                header: 'Ідентифікатор',
                accessorKey: 'details',
            },
            {
                id: 'okpo',
                header: 'Код',
                accessorKey: 'okpo',
            },
            {
                id: 'fop',
                header: 'ФОП',
                accessorKey: 'fop',
            },
            {
                id: 'date_end',
                header: 'Дійсна до',
                accessorKey: 'date_end',
                cell: ({ row }) => {
                    const date = row.original.date_end ? format(new Date(row.original.date_end), 'dd.MM.yyyy') : '';
                    return <span>{date}</span>;
                },
            },
            {
                id: 'price',
                header: 'Ціна',
                accessorKey: 'price',
            },
            {
                id: 'actions',
                header: 'Дії',
                cell: ({ row }) => {
                    const { id, lastLicense, date_end, details, type } = row.original;
                    return (
                        <div className="row p-0 m-0 text-center align-items-center">
                            <div className="col w-25 p-0 m-1">
                                <CreateIcon onClick={() => { dispatch(setCurrentProduct(id)); setIsModalRenewalOpen(true); }} />
                            </div>
                            {lastLicense && date_end && details && type &&
                                 <div
                                 onClick={() => {
                                   
                                     dispatch(downloadLicense({ id: lastLicense, date_end, details, type}));
                                 }}
                                 className="col p-0 m-1"
                             >
                                 <DownloadIcon />
                             </div>
                            }
                            <div className="col w-25 p-0 m-1">
                                <TrashIcon onClick={() => { dispatch(setCurrentProduct(id)); setIsModalDeleteOpen(true); }} />
                            </div>
                        </div>
                    );
                },
            },
        ],
        [data]
    );
    return (
        <>
            <div className="row row m-0 p-0">
                <ul className="nav m-2">
                    <li className="nav-item">
                        <button type="button" className="btn btn-primary" onClick={openModal} >Створити продукт</button>
                    </li>
                </ul>
            </div>
            <div className="row row m-0 p-0">
                <Table rest={{data, columns, search: false, pagination: false, filters: false, onDoubleClick: setIsModalOpen}}/>
            </div>
            <CreateProduct rest={{customerId, isModalOpen, data, closeModal, user}}/>
            <DeleteModal rest={{itemType: 'product', action: () => deleteProduct({id}), isModalDeleteOpen, closeDeleteModal: () => setIsModalDeleteOpen(false)}}/>
            <LicenseRenewal rest={{id, isModalOpen: isModalRenewalOpen, closeModal: () => setIsModalRenewalOpen(false)}}/>
        </>
    )

};

const Licenses = ({rest}) => {
    const dispatch = useDispatch();
    const { customerId, user } = rest;
    const id = useSelector(state => state.licenses.currentLicense);
    const data = useSelector(state => state.licenses.licenses).filter(l => l.customer === customerId);
    const goods = useSelector(state => state.appInfo.goods);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    const columns = useMemo(
        () => [
            {
                id: 'date_doc_from',
                header: 'Дата з',
                accessorKey: 'date_doc',
                cell: ({ row }) => {
                    const date = format(new Date(row.original.date_doc), 'dd.MM.yyyy');
                    return <span>{date}</span>;
                },
            },
            {
                id: 'date_doc_to',
                header: 'Дата по',
                accessorKey: 'date_end',
                cell: ({ row }) => {
                    const date = format(new Date(row.original.date_end), 'dd.MM.yyyy');
                    return <span>{date}</span>;
                },
            },
            {
                id: 'details',
                header: 'Індетифікатор',
                accessorKey: 'details',
            },
            {
                id: 'okpo',
                header: 'Код',
                accessorKey: 'okpo',
            },
            {
                id: 'fop',
                header: 'ФОП',
                accessorKey: 'fop',
            },
            {
                id: 'type',
                header: 'Тип',
                accessorKey: 'type',
                cell: ({ row }) => {
                    const good = goods.find(g => g.id === row.original.type);
                    return good ? good.name : '';
                },
            },
            {
                id: 'amount',
                header: 'Сума',
                accessorKey: 'amount',
            },
            {
                id: 'paid',
                header: 'Оплата',
                accessorKey: 'paid',
                cell: ({ row }) => {
                    const paid = row.original.paid;
                    return paid === "1" ? 'Оплачено' : paid === "2" ? "Тест" : 'Нема';
                },
            },
            {
                id: 'vladik',
                header: 'Владік',
                accessorKey: 'paid_v',
                cell: ({ row }) => {
                    const paid = row.original.paid_v;
                    return paid === "1" ? 'Оплачено' : 'Нема';
                },
            },
            {
                id: 'actions_license',
                header: 'Дії',
                cell: ({ row }) => {
                    const { details, date_end, type, id } = row.original;
                    return (
                        <div className="row p-0 m-0 text-center align-items-center">
                            <div 
                                onClick={() => {
                                    const license = {
                                        id,
                                        details: details,
                                        date_end: date_end,
                                        type: Number(type),
                                    };
                                    dispatch(downloadLicense(license));
                                }} 
                                className="col p-0 m-1"
                            >
                                <DownloadIcon />
                            </div>
                            <div className="col p-0 m-1">
                                <TrashIcon onClick={() => { dispatch(setCurrentLicense(id)); setIsModalDeleteOpen(true); }} />
                            </div>
                        </div>
                    );
                },
            },
        ],
        []
    )

    return (
        <>
            <div className="row row m-0 p-0">
                <ul className="nav m-2">
                    <li className="nav-item">
                        <button type="button" className="btn btn-primary" onClick={openModal} >Створити ліцензію</button>
                    </li>
                </ul>
            </div>
            <div className="row row m-0 p-0">
                <Table rest={{data: data, columns, search: false, pagination: false, filters: false, onDoubleClick: setIsModalOpen}}/>
            </div>
            <CreateLicense rest={{customerId, isModalOpen, data, closeModal, user}}/>
            <DeleteModal rest={{itemType: 'license', action: () => deleteLicense({id}), isModalDeleteOpen, closeDeleteModal: () => setIsModalDeleteOpen(false)}}/>
        </>
    )
    
}

export const CreateLicense = ({rest}) => {
    const dispatch = useDispatch();
    const goods = useSelector(state => state.appInfo.goods);
    const user = useSelector(state => state.users.user)

    const {customerId = null, isModalOpen, closeModal, data = [] } = rest;

    const license = data.find(l => l.id === isModalOpen) || [];

    const { id = null, customer = null, type = null, date_doc = '', date_end = '',  details = null,  amount = null, paid = null, paid_v = null} = license;

    const products  = useSelector(state => state.products.products)

    return (
        <Modal isOpen={isModalOpen} onClose={closeModal}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="exampleModalLabel">Нова ліцензія</h1>
                        <button type="button" className="btn-close" onClick={closeModal}></button>
                    </div>
                    <div className="modal-body">
                    <Formik
                        initialValues={{ customer: customerId ?? customer ?? '', date_doc: date_doc ?? '', date_end: date_end ?? '', type: type ?? '',  details: details ?? '',  price:  amount ?? 0, paid: paid ?? '0', paidSec: paid_v ?? '0'}}
                        validationSchema={validationSchemaLicenses}
                        onSubmit={(values, { setSubmitting, resetForm }) => {
                            setSubmitting(true);
                        
                            if (id) {
                                dispatch(updateLicense({license: {...values, id, paid: Number(values.paid), paid_v:  Number(values.paidSec), customer: customer ?? values.customer}}))
                                .then(data => {
                                    if (data.payload.status === 'ok') {
                                        dispatch(updateLastDate({details, date_end: data.payload.data.date_end}));
                                        dispatch(addToast({id: Date.now(), message: data.payload.message, status: data.payload.status}));
                                        closeModal();
                                    } else {
                                        dispatch(addToast({id: Date.now(), message: data.payload.message, status: data.payload.status}));
                                    }
                                    setSubmitting(false);
                                })
                            } else {
                                dispatch(addLicense({license: {...values, paid: Number(values.paid), paid_v:  Number(values.paidSec), customer: customerId ?? values.customer}}))
                                .then(data => {
                                    if (data.payload.status === 'ok') {
                                        dispatch(addToast({id: Date.now(), message: data.payload.message, status: data.payload.status}));
                                        closeModal();
                                    } else {
                                        dispatch(addToast({id: Date.now(), message: data.payload.message, status: data.payload.status}));
                                    }
                                    setSubmitting(false);
                                })
                            }
                            resetForm();
                            
                        }}
                    >
                        {({ handleSubmit, isSubmitting, setFieldValue, values }) => {
                            const customerFilter = values.customer || customerId || customer;
                            const filteredProducts = customerFilter ? products.filter(p => p.customer === customerFilter) : [];

                            return (
                            <form onSubmit={handleSubmit} className="row">
                                <div className="col-6">
                                    <DateInput
                                        label="Дата з"
                                        name="date_doc"
                                        onChange={(val) => {
                                        setFieldValue('date_doc', val);
                                        const dateEnd = addYears(val, 1);
                                        setFieldValue('date_end', dateEnd);
                                        }}
                                    />
                                </div>
                                <div className="col-6">
                                    <DateInput
                                        label="Дата по"
                                        name="date_end"
                                        onChange={(val) => setFieldValue('date_end', val)}
                                    />
                                </div>
                                <div className="col-6">
                                    {!customerId &&  !customer && 
                                        <CustomersSelect
                                            label="Клієнт"
                                            id="customer"
                                            name="customer"
                                        />
                                    }
                                </div>
                                <div className="col-6">
                                    <Select
                                        label="Індетифікатор"
                                        id="details"
                                        name="details"
                                        onChange={(e) => {
                                            const selectedValue = e.target.value;
                                            const type = filteredProducts.find(p => p.details === selectedValue);
                                            const typeValue = type ? goods.find(g => g.id === type.type) : '';
                                            setFieldValue('details', selectedValue);
                                            setFieldValue('type', typeValue?.type);
                                            setFieldValue('price', typeValue?.price);
                                           
                                        }}
                                    >
                                        <option disabled value="">
                                            Виберіть ідентифікатор
                                        </option>
                                        {filteredProducts.map((item) => {
                                            const { details, id } = item;
                                            return (
                                                <option key={id} value={details}>
                                                    {details}
                                                </option>
                                            );
                                        })}
                                    </Select>
                                </div>
                                <div className="col-6">
                                    <Select
                                        label="Тип"
                                        id="type"
                                        name="type"
                                        onChange={(e) => {
                                            const selectedValue = e.target.value;
                                            const typeValue = selectedValue ? goods.find(g => g.id === selectedValue) : '';
                                            setFieldValue('type', typeValue?.type);
                                            setFieldValue('price', typeValue?.price);
                                           
                                        }}
                                    >
                                        {values.details &&
                                            goods.map((item) => {
                                                    const { type, id, name } = item;
                                                    return (
                                                        <option key={id} value={type} >
                                                            {name}
                                                        </option>
                                                    );
                                            })
                                        }
                                    </Select>
                                </div>
                                <div className="col-6">
                                    <TextInput label="Ціна" id="price" name="price"  disabled={user.rigths !== "1"}/>
                                </div>
                                <div className="col-6">
                                    <Select
                                        label='Оплата'
                                        id="paid"
                                        name='paid'
                                        onChange={(e) => {
                                            const selectedType = values.type;
                                            const selectedValue = e.target.value;
                                            const typeValue = goods.find(g => g.id === selectedType);

                                            setFieldValue('paid', selectedValue);
                                            if (selectedValue === '2') {
                                                setFieldValue('price', '0');
                                            } else {
                                                setFieldValue('price', typeValue?.price);
                                            }
                                           
                                        }}
                                    >
                                        <option value="0">Ні</option>
                                        <option value="1">Так</option>
                                        <option value="2">Тест</option>
                                    </Select>
                                </div>
                                {user.id === '5' &&
                                    <div className="col-6">
                                        <Select
                                            label='Оплата Владік'
                                            id="paidSec"
                                            name='paidSec'
                                        >
                                            <option value="0">Ні</option>
                                            <option value="1">Так</option>
                                        </Select>
                                    </div>
                                }
                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        className="btn btn-secondary me-2"
                                        onClick={closeModal}
                                    >
                                        Закрити
                                    </button>
                                    <button
                                        type="submit"
                                        className="btn btn-primary w-atuo me-2"
                                        disabled={isSubmitting}
                                    >
                                        {isSubmitting ? 'Збереження...' : 'Зберегти'}
                                    </button>
                                </div>
                            </form>
                            );
                        }}
                        </Formik>
                    </div>     
                </div>
            </div>
        </Modal>
    )
}

const LicenseRenewal = ({rest}) => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.users.user);console.log(user)

    const {id, isModalOpen, closeModal, data = [],} = rest;
    return (
        <Modal isOpen={isModalOpen} onClose={closeModal}>
            <div className="modal-dialog ">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="exampleModalLabel">Нова ліцензія</h1>
                        <button type="button" className="btn-close" onClick={closeModal}></button>
                    </div>
                    <div className="modal-body">
                        <Formik
                            initialValues={{id: id, period: 'default', custom_period: '', paid: '0', paid_v: false, downloadAfter: false}}
                            validationSchema={Yup.object().shape({
                                period: Yup.string()
                                    .required('Оберіть період!')
                                    .test('warn', 'Оберіть період!', (value) => value !== 'default'),
                                custom_period: Yup.string()
                                    .when('period', {
                                        is: (value) => value === 'custom',
                                        then: (schema) => schema.required('Оберіть дату'),
                                        otherwise: (schema) => schema.notRequired(),
                                    })
                                })}
                            onSubmit={(values, { setSubmitting, resetForm }) => {
                                setSubmitting(true);
                                dispatch(renewal({data: {id, ...values}}))
                                .then(res => {
                                    const {message, status, data} = res.payload;
                                    if (status === 'ok') {
                                        dispatch(updateLastDate({id, details: data.details, lastLicense: data.id, date_end: data.date_end}));
                                        dispatch(addToast({id: Date.now(), message, status}));

                                        if (values.downloadAfter) {
                                            dispatch(downloadLicense({details: data.details, date_end: data.date_end, type: data.type, id: data.id}));
                                        }

                                        setSubmitting(false);
                                        closeModal();
                                    } else {
                                        setSubmitting(false);
                                    }
                                })

                                resetForm();
                            }}
                        >
                            {({ handleSubmit, isSubmitting, values }) => (
                                <form onSubmit={handleSubmit} className="row ">
                                        <div className="col-12">
                                            <Select
                                                label="На період"
                                                name="period"
                                            >
                                                <option value="default" disabled>Оберіть період</option>
                                                <option value="1_month">1 місяць</option>
                                                <option value="3_months">3 місяці</option>
                                                <option value="6_months">6 місяців</option>
                                                <option value="1_year">1 рік</option>
                                                <option value="custom">Свій вибір</option>
                                            </Select>
                                        </div>
                                        {values.period && values.period === 'custom' &&
                                            <div className="col-12">
                                                <DateInput
                                                    label="Період"
                                                    name="custom_period"
                                                />
                                            </div>
                                        }
                                         <div className="col-12">
                                            <Select
                                                label='Оплата'
                                                id="paid"
                                                name='paid'
                                            >
                                                <option value="0">Ні</option>
                                                <option value="1">Так</option>
                                                <option value="2">Тест</option>
                                            </Select>
                                        </div>
                                        {user.id === '5' && 
                                            <div className="col-12">
                                                <Checkbox
                                                    id='paid_v'
                                                    name='paid_v'
                                                    label={ <span>Оплата Владік</span> }
                                                />
                                            </div>
                                        }
                                        <div className="col-12">
                                            <Checkbox
                                                id='downloadAfter'
                                                name='downloadAfter'
                                                label={ <span>Завантажити</span> }
                                            />
                                        </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary me-2" onClick={closeModal}>Закрити</button>
                                        <button  type="submit" className="btn btn-primary w-atuo me-2" disabled={isSubmitting}>
                                            {isSubmitting ? "Обробка..." : "Створити"}
                                        </button>
                                    </div>
                                </form>
                            )}
                        </Formik>
                    </div>     
                </div>
            </div>
        </Modal>
    )
}

const CreateProduct = ({rest}) => {
    const dispatch = useDispatch();
    const {customerId, isModalOpen, closeModal, data = [], user} = rest;
    const goods = useSelector(state => state.appInfo.goods);

    const product = data.find(d => d.id === isModalOpen) || [];

    const { id = null, type = null,  details = null, fop = null, okpo = null, price= null, date_end = null, lastLicense = null,} = product;
    

    return (
        <Modal isOpen={isModalOpen} onClose={closeModal}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="exampleModalLabel">Новий продукт</h1>
                        <button type="button" className="btn-close" onClick={closeModal}></button>
                    </div>
                    <div className="modal-body">
                    <Formik
                        initialValues={{ type: type ?? goods[0].type ?? '0',  details: details ?? '', fop: fop ?? '', okpo: okpo ?? '', price:  price ?? goods[0].price ?? 0, date_end: date_end ?? ''}}
                        validationSchema={validationSchemaProducts}
                        onSubmit={(values, { setSubmitting, resetForm }) => {
                            setSubmitting(true);
                            if (id) {
                                dispatch(updateProduct({product: {...values, id, customer: customerId}}))
                                .then(data => {
                                    if (data.payload.status === 'ok' && data.payload.data) {
                                        const id = data.payload.data
                                        const dateEnd = values.date_end !== '' ? format(new Date(values.date_end), 'yyyy-MM-dd') : '';

                                        dispatch(updateProductState({
                                            ...values,
                                            date_end: dateEnd,
                                            customer: customerId,
                                            lastLicense,
                                            id
                                        }));
                                        if (lastLicense && dateEnd) {
                                            dispatch(updateLicenseDate({id: lastLicense, 'date': dateEnd}))
                                        }
                                        dispatch(addToast({id: Date.now(), message: data.payload.message, status: data.payload.status}));
                                        setSubmitting(false);
                                        closeModal();
                                    } else {
                                        dispatch(addToast({id: Date.now(), message: data.payload.message, status: data.payload.status}));
                                        setSubmitting(false);
                                    }
                                })
                            } else {
                                dispatch(addProduct({product: {...values, customer: customerId}}))
                                .then(data => {
                                    if (data.payload.status === 'ok' && data.payload.data) {
                                        const id = data.payload.data
                                        dispatch(updateProductState({...values, customer: customerId, id}));
                                        dispatch(addToast({id: Date.now(), message: data.payload.message, status: data.payload.status}));
                                        setSubmitting(false);
                                         closeModal();
                                    } else {
                                        dispatch(addToast({id: Date.now(), message: data.payload.message, status: data.payload.status}));
                                        setSubmitting(false);
                                    }
                                })
                            }
                            resetForm();
                        }}
                    >
                        {({ handleSubmit, isSubmitting, setFieldValue, values }) => (
                            <form onSubmit={handleSubmit} className="row ">
                                <div className="col-6">
                                    <Select
                                        label='Тип'
                                        id="type"
                                        name='type'
                                        onChange={(e) => {
                                            const selectedType = e.target.value;
                                            setFieldValue('type', selectedType);

                                            const selectedItem = goods.find((item) => item.type === selectedType);
                                            if (selectedItem) {
                                                setFieldValue('price', selectedItem.price);
                                            }
                                        }}
                                    >
                                        {goods.map((item, index) => {
                                            const {id, name, type} = item;

                                            return (
                                                <option key={id} value={type}>{name}</option>
                                            )
                                        })}
                                    </Select>
                                </div>
                                <div className="col-6">
                                    <TextInput 
                                        label='Індетифікатор'
                                        id="details"
                                        name='details'
                                    />
                                </div>
                                <div className="col-6">
                                    <TextInput 
                                        label='ФОП'
                                        id="fop"
                                        name='fop'
                                    />
                                </div>
                                <div className="col-6">
                                    <TextInput 
                                        label='КОД'
                                        id="okpo"
                                        name='okpo'
                                    />
                                </div>
                                <div className="col-6">
                                    <TextInput 
                                        label='Ціна'
                                        id="price"
                                        name='price'
                                        disabled={user.rigths !== "1"}
                                    />
                                </div>
                                {date_end &&
                                     <div className="col-6">
                                        <DateInput
                                            label="Дійсна до"
                                            name="date_end"
                                            onChange={(val) => {
                                                setFieldValue('date_end', val);
                                            }}
                                        />
                                    </div>
                                }
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary me-2" onClick={closeModal}>Закрити</button>
                                    <button  type="submit" className="btn btn-primary w-atuo me-2" disabled={isSubmitting}>
                                        {isSubmitting ? "Збереження..." : "Зберегти"}
                                    </button>
                                </div>
                            </form>
                        )}
                    </Formik>
                    </div>     
                </div>
            </div>
        </Modal>
    );
}

const CustomerForm = ({rest}) => {
    const dispatch = useDispatch();
    const offices = useSelector(state => state.appInfo.offices);
    const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);

    const { customer: {id, 'name': companyName = '', 'fullname': manager = '', 'adress':address = '', phone = '', office = '0', url = ''}, openModal } = rest;
    
    const content = (
        <div className="row">
            <Formik
                initialValues={{ id: id, companyName: companyName,  address: address, manager: manager, phone: phone, office: office, url: url}}
                validationSchema={validationSchema}
                enableReinitialize={true}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(true);
                     
                    dispatch(updateCustomer({customer: values}))
                    .then(data => {
                        if (data.payload.status === 'ok') {
                            dispatch(update(values))
                            dispatch(addToast({id: Date.now(), message: data.payload.message, status: data.payload.status}));
                            setSubmitting(false);
                        } else {
                            dispatch(addToast({id: Date.now(), message: data.payload.message, status: data.payload.status}));
                            setSubmitting(false);
                        }
                    })
                   
                }}
            >
                {({ handleSubmit, isSubmitting }) => (
                    <form onSubmit={handleSubmit} className="row ">
                        <div className="col-2">
                            <TextInput 
                                label='ID'
                                id="id"
                                name='id'
                                disabled
                            />
                        </div>
                        <div className="col-10">
                            <TextInput 
                                label='Назва об`єкта'
                                id="companyName"
                                name='companyName'
                            />
                        </div>
                        <div className="col-6">
                            <TextInput 
                                label='Адреса'
                                id="address"
                                name='address'
                            />
                        </div>
                        <div className="col-6">
                            <TextInput 
                                label='Відповідальна особа'
                                id="manager"
                                name='manager'
                            />
                        </div>
                        <div className="col-6">
                            <TextInput 
                                label='Телефон'
                                id="phone"
                                name='phone'
                            />
                        </div>
                        <div className="col-6">
                            <Select
                                 label='Офіс'
                                 id="office"
                                 name='office'
                            >
                                <option value="0">Немає</option>
                                {offices.map(item => {
                                    const {id, name, type} = item;

                                    return (
                                        <option key={id} value={type}>{name}</option>
                                    )
                                })}
                            </Select>
                        </div>
                        <div className="col-6">
                            <TextInput 
                                label='URL'
                                id="url"
                                name='url'
                            />
                        </div>
                        <div className="col-12">
                            <button  type="submit" className="btn btn-primary w-atuo me-2" disabled={isSubmitting}>
                                {isSubmitting ? "Збереження..." : "Зберегти зміни"}
                            </button>
                            <div onClick={() => {dispatch(setCurrentCustomer(id)); setIsModalDeleteOpen(true)}}  className="btn btn-danger w-atuo  me-2" >Видалити клієнта</div>
                            <div  className="btn btn-primary w-atuo" onClick={openModal}>Перенести ліцензії</div>
                        </div>
                    </form>
                )}

            </Formik>
            <DeleteModal rest={{itemType: 'customer', action: () => deleteCustomer({id}), tabAction: () => removeTab(id), isModalDeleteOpen, closeDeleteModal: () => setIsModalDeleteOpen(false)}}/>
        </div>
    )

    return content
};


const validationSchemaImportLicenses = Yup.object().shape({
    importedCustomer: Yup.string()
        .required('Оберіть користувача!'),
});

const validationSchemaLicenses = Yup.object().shape({
    date_doc: Yup.string()
        .required('Дата початку обов`язкова'),
    date_end: Yup.string()
        .required('Дата закінчення обов`язкова'),
    customer: Yup.string()
        .required('Оберіть користувача!'),
    details: Yup.string()
        .required('Індетифікатор є обовязковим!'),
    type: Yup.string()
        .required('Оберіть тип ліцензії!')
});

export const validationSchema = Yup.object().shape({
    companyName: Yup.string()
        .required('Назва об`єкта обовязкова!')
})
export const validationSchemaProducts = Yup.object().shape({
    details: Yup.string()
        .required('Індетифікатор є обовязковим!')
})

export default CustomerPage;